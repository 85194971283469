
#editor {
  min-height: calc(100vh - 200px);
  margin-bottom: 90px;
}
div.ProseMirror {
  min-height: calc(100vh - 200px);
  outline: none;
  padding: 8px;
  white-space: pre-wrap;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.07);
  background-color: white;
  border: 1px solid #DAE2ED;
}
div.ProseMirror-focused {
  border: 1px solid var(--theme-color);
}
div.ProseMirror .placeholder {
  color: gray;
  font-size: 0.9em;
}
div.ProseMirror-focused .placeholder {
  display: none;
}
div.ProseMirror p:first-child {
  margin-top: 0;
}
.problem {
  background: #fdd;
  border-bottom: 1px solid #f22;
  margin-bottom: -1px;
}
.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #dadce0;
  box-shadow: 0 1px 3px 1px rgba(60,64,67,.15);
  padding: 5px;
  border-radius: 5px;
}
.tooltip span {
  cursor: pointer;
  padding: 0px 5px;
}
.tooltip.hidden {
  display: none;
}
.tooltip .highlighted, .tooltip span:hover {
  background-color: var(--dark-theme-color);
  color: white;
}
#dictionary-container {
  height: 100%;
}
