@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    --light-theme-color: #fae1c9;
    --theme-color: #ea7e64;
    --dark-theme-color: #d9745c;
    --very-dark-theme-color: #62372d;
}

html, body, #root, .app-container {
    height: 100%;
    color: #333333;
}

body {
    font-family: 'Rubik', sans-serif;
    display: flex;
    flex-direction: column;
}

.theme-color {
    color: var(--theme-color);
}

.page-header {
    background-color: var(--light-theme-color);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
}

@media (max-width: 480px) {
    .page-header {
        height: 45px;
    }
}

.page-header #header-logo {
    height: 32px;
    margin-top: 4px;
}

.page-header #messenger-icon {
    height: 24px;
}

.link:focus {
  outline: none;
}

a {
  text-decoration: none;
  opacity: 1;
  transition: color .15s ease-in;
}

a:hover {
  opacity: .5;
  text-decoration: none;
  transition: opacity .15s ease-in;
}

@keyframes loading {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

#loading-message {
    animation: loading 1s ease infinite;
}

/* swap language bar */

#language-display-bar {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
}

.swap-languages {
    background-image: url('./swap_arrows.png');
    background-repeat: no-repeat;
    background-position: center;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter.right-language, .slide-fade-leave-to.right-language {
  transform: translateX(-10px);
  opacity: 0;
}

/* end swap language bar */

.warning {
    background-color: var(--light-theme-color);
    color: var(--very-dark-theme-color);
    border-radius: 25px;
    padding: 1rem 1.5rem;
    text-align: center;
}
.warning .warning-icon {
    display: block;
    margin-bottom: 0.5rem;
}

/* begin search container */

.search-container #clear-totranslate {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.2rem;
    color: darkgrey;
}

.search-container #clear-totranslate:hover {
    color: black;
}

.search-container #clear-totranslate .material-icons {
    font-size: 14px;
}

.translate-button-container {
    display: flex;
    justify-content: flex-end;
}

.translate-button {
    background-color: transparent;
    border: 2px solid var(--theme-color);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    margin: 10px 0px;
    padding: 6px 20px;
    font-size: 15px;
    outline: none;
}

.translate-button:hover {
  opacity: 0.5;
}

.translate-icon {
    margin-right: 5px;
    font-size: 15px;
}

.translate-button.loading {
    animation: loading 1s ease infinite;
}

#to-translate {
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
    border: none;
    resize: vertical;
}

#to-translate:focus {
    box-shadow: 0 0 0pt 2px var(--theme-color);
    outline: none;
    border: none;
}

/* end search container */

/* begin search results */

.top-7px {
    top: 7px;
}

div#translator {
    flex: 1 0 auto;
    background-color: rgba(var(--b3f,250,250,250),1);
    padding-bottom: 60px;
}

/* end search results */

@media (max-width: 480px) and (max-height: 400px) {
    .site-footer.hide-keyboard-open {
        display: none;
    }
}

.modal-mask textarea {
    padding: 0.7em;
    transition: box-shadow 0.3s ease-in-out;
    border-color: grey;
    border-radius: 2px;
}

.modal-mask textarea:focus {
    outline: none;
    border: 2px solid var(--dark-theme-color);
}

.modal-mask button {
    text-transform: uppercase;
    font-weight: 600;
    background: transparent;
    line-height: 1em;
    padding: 0.6em 0.9em;
    transition: all 0.3s ease-in-out;
    border: 2px solid grey;
    color: grey;
    cursor: pointer;
}

.modal-mask button[type="submit"] {
    border: 2px solid var(--dark-theme-color);
    color: var(--dark-theme-color);
}

/* support us */

#support-us {
    position: fixed;
    bottom: 4rem;
    font-size: 12px;
    text-align: center;
}

/* footer */

#navigation-row {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(216, 216, 216);
    background-color: white;
    font-size: 14px;
    z-index: 1;
}

#navigation-row a {
  padding: 15px;
  margin: 0 10px;
}

#navigation-row a.Mui-selected {
  color: var(--theme-color);
}

#navigation-row span.MuiBottomNavigationAction-label {
    text-wrap: nowrap;
}

@media (max-width: 480px) {
}

@media (max-height: 400px) {
    #support-us, #navigation-row {
        display: none;
    }
}


/* spell checker */

.content-to-check {
    margin-top: 20px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #aaa;
    font-size: 16px;
    min-height: 250px;
    padding: 10px;
}

.content-to-check:focus {
  border: 2px solid var(--theme-color);
  outline: none;
}

